<template>
  <div class="py-8 bg-offWhite px-4">
    <div class="container">
      <div class="text-center py-8 mx-auto max-w-2xl">
        <span class="text-5xl font-heading leading-normal">Register for our Free Webclass to see Capsho in action!</span>
      </div>
      <div class="pb-8">
        <a
          href="https://www.capshounlocked.com/webclass"
          target="_blank"
        >
          <button class="font-body font-bold bg-primaryDark text-white py-4 px-4 rounded-full">
            Sign up for our free Webclass
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUpButton'
}
</script>