export default function () {
  return {
    podcasts: [],
    podcastInfo: null,
    transcripts: {},
    podcastProgress: '',
    uploadedPodcastURL: '',
    transcript: {},
    transcriptionData: {}
  }
}
