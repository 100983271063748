export const getNewUserProfile = (state) => {
  return state.newUserProfile
}

export const getSets = (state) => {
  return state.sets
}

export const getAnswers = (state) => {
  return state.answers
}

export const getQuestions = (state) => {
  return state.questions
}
